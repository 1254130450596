<template>
  <ion-header>
    <ion-toolbar>
      <router-link to="/">
        <ion-img src="/img/logo.svg" class="logo" />
      </router-link>
      <ion-buttons slot="end">
        <router-link v-if="searchEnabled" :to="{ name: $routeNames.SEARCH }">
          <ion-button>
            <ion-icon name="search" />
          </ion-button>
        </router-link>
        <router-link :to="{ name: $routeNames.CHECKIN }">
          <ion-button>
            <ion-text color="white" class="pr-2">
              <span>{{ $t('checkin') }}</span>
            </ion-text>

            <ion-icon name="qr-scanner" />
          </ion-button>
        </router-link>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</template>

<script>
  import { FEATURE_FLAG_MAPPING } from "@/configs";
  import { CURRENT_USER_GETTER } from "@/store/store-getters";

  export default{
    computed: {
      searchEnabled() {
        return this.$optimizely.isFeatureEnabled(FEATURE_FLAG_MAPPING.GLOBAL_SEARCH, this.$store.getters[CURRENT_USER_GETTER]?.id, {
          user_id: this.$store.getters[CURRENT_USER_GETTER]?.id
        });
      },
    },
  }
</script>

<style scoped>
    ion-toolbar {
        --background: var(--ion-color-tertiary)
    }

    .ios ion-title {
        padding-left: 5px;
    }


</style>
