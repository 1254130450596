<template>
  <div>
    <Header />

    <ion-tabs>
      <ion-tab tab="/news">
        <ion-vue-router name="news" />
      </ion-tab>

      <ion-tab tab="/subscribed">
        <ion-vue-router name="subscribed" />
      </ion-tab>

      <!-- Match by "app.speakers" route name -->
      <ion-tab tab="/events">
        <ion-vue-router name="events" />
      </ion-tab>

      <!-- Match by an array of route names -->
      <ion-tab tab="/facilities">
        <ion-vue-router name="facilities" />
      </ion-tab>

      <ion-tab tab="/profile">
        <ion-vue-router name="profile" />
      </ion-tab>

      <ion-tab tab="/thanks">
        <ion-vue-router name="thanks" />
      </ion-tab>

      <ion-tab tab="/error-404">
        <ion-vue-router name="error-404" />
      </ion-tab>

      <ion-tab tab="/error-403">
        <ion-vue-router name="error-403" />
      </ion-tab>

      <ion-tab tab="home" :routes="'home'">
        <ContentContainer>
          <ion-grid class="container">
            <ion-row>
              <ion-col>
                <ion-text class="text-xl text-center ">
                  <h1 class="mb-10">
                    {{ $t('apppoweredby') }}
                  </h1>
                </ion-text>
              </ion-col>
            </ion-row>

            <ion-row class="grid gap-10 px-5 grid-cols-3 md:grid-cols-7">
              <img src="/icons/partners/asset-1.svg" />
              <img src="/icons/partners/asset-2.svg" />
              <img src="/icons/partners/asset-3.svg" />
              <img src="/icons/partners/asset-4.svg" />
              <img src="/icons/partners/asset-5.svg" />
              <img src="/icons/partners/asset-6.svg" />
              <img src="/icons/partners/asset-7.svg" />
              <img src="/icons/partners/asset-8.svg" />
              <img src="/icons/partners/asset-9.svg" />
              <img src="/icons/partners/asset-10.svg" />
              <img src="/icons/partners/asset-11.svg" />
              <img src="/icons/partners/asset-12.svg" />
              <img src="/icons/partners/asset-13.svg" />
              <img src="/icons/partners/asset-14.svg" />
            </ion-row>
          </ion-grid>
        </ContentContainer>
        <ion-footer v-if="appVersion">
          <ion-row>
            <ion-col class="text-center">
              <ion-text color="medium"><sub>v{{ appVersion }}</sub></ion-text>
            </ion-col>
          </ion-row>
        </ion-footer>
      </ion-tab>

      <!-- Use v-slot:bottom with Vue ^2.6.0 -->
      <template slot="bottom">
        <ion-tab-bar color="tabs">
          <ion-tab-button tab="/news">
            <ion-icon name="paper" />
            <ion-label>{{ $t("infoabbrevation") }}</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="/facilities">
            <ion-icon name="flag" />
            <ion-label>{{ $t("facilities") }}</ion-label>
          </ion-tab-button>

          <!-- Provide a custom route to navigate to -->
          <ion-tab-button tab="/events">
            <ion-icon name="calendar" data-cy="event-tab" />
            <ion-label>{{ $t("events") }}</ion-label>
          </ion-tab-button>

          <!-- Provide extra data to route -->
          <ion-tab-button data-cy="profile-tab" tab="/profile">
            <ion-icon name="person" />
            <ion-label>{{ $t("profile") }}</ion-label>
          </ion-tab-button>
        </ion-tab-bar>
      </template>
    </ion-tabs>
  </div>


  <!-- Listen to before and after tab change events -->
</template>

<script>
import Header from '@/components/Header'
import { SplashScreen } from '@capacitor/splash-screen';
import { mapGetters } from "vuex";

// @ is an alias to /src
export default {
  name: 'Home',
  components: {
    Header,
  },
  computed: {
    ...mapGetters(['appVersion']),
  },
  mounted() {
    SplashScreen.hide();
  },
}
</script>


